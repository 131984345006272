import React from "react";
import {
  UpdatePageMutationVariables,
  usePageQuery,
  useUpdatePageMutation,
  useUpdateNewsMutation,
  UpdateNewsMutationVariables,
  useNewsQuery,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateNews] = useUpdateNewsMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const { loading, error, data } = useNewsQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to={"/news"} replace />;
  }

  const handleUpdateNews = async (data: UpdateNewsMutationVariables) => {
    try {
      await updateNews({
        variables: {
          ...data,
        },
      });

      return toast({
        status: "success",
        title: "Success",
        description: "News updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/news");
        },
      });
    } catch (error: any) {
      console.log(error.message);
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };

  return (
    <Form
      actionType="UPDATE"
      data={data?.findUniqueNews}
      id={id}
      handleUpdateNews={handleUpdateNews}
    />
  );
};

export default Update;
