import React from "react";
import {
  UpdateChoiceMutationVariables,
  useUpdateChoiceMutation,
  useChoiceQuery,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";

interface Props {
  questionId: string;
  id: string;
}

const Update: React.VFC<Props> = ({ id, questionId }) => {
  const [updateQuestion] = useUpdateChoiceMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const { loading, error, data } = useChoiceQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to={`/question/detail/${questionId}`} replace />;
  }

  const handleUpdateChoice = async (data: UpdateChoiceMutationVariables) => {
    try {
      await updateQuestion({
        variables: {
          ...data,
        },
      });

      return toast({
        status: "success",
        title: "Success",
        description: "Choice updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation(`/question/detail/${questionId}`);
        },
      });
    } catch (error: any) {
      console.log(error.message);
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };

  return (
    <Form
      actionType="UPDATE"
      questionId={questionId}
      data={data?.choice}
      id={id}
      handleUpdateChoice={handleUpdateChoice}
    />
  );
};

export default Update;
