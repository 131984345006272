import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateContentMutationVariables,
  useCreateContentMutation,
} from "../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";

const Create = () => {
  const [createContent] = useCreateContentMutation();
  const params = useParams();
  const field = params.field;
  const navigation = useNavigate();
  const toast = useToast();
  const handleCreateContent = async (data: CreateContentMutationVariables) => {
    try {
      await createContent({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "Success",
        description: "Content added",
        duration: 1000,
        onCloseComplete: () => {
          return navigation(`/content/${field}/${params.id!}`);
        },
      });
    } catch (error) {
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };
  return (
    <Form
      handleCreateContent={handleCreateContent}
      actionType="CREATE"
      field={field!}
      fieldId={params.id!}
    />
  );
};

export default Create;
