import { Box, Heading } from "@chakra-ui/react";

function Dashboard() {
  return (
    <Box p={4} bgColor="white" shadow="md" rounded="base">
      ads
    </Box>
  );
}

export default Dashboard;
