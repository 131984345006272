import { SetStateAction, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  Select,
} from "@chakra-ui/react";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";

import {
  CreateUserMutationVariables,
  UpdateUserMutationVariables,
  UserQuery,
  UserType,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";

interface Props {
  handleCreateUser?: (data: CreateUserMutationVariables) => Promise<any>;
  handleUpdateUser?: (data: UpdateUserMutationVariables) => Promise<any>;
  id?: string;
  data?: UserQuery["user"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({
  handleCreateUser,
  handleUpdateUser,
  id,
  data,
  actionType,
}) => {
  const toast = useToast();

  const [name, setName] = useState(data?.name || "");
  const [surname, setSurname] = useState(data?.surname || "");
  const [password, setPassword] = useState("");
  const [companyEmailPassword, setCompanyEmailPassword] = useState("");
  const [email, setEmail] = useState(data?.email || "");
  const [phone, setPhone] = useState(data?.phoneNumber || "");
  const [userType, setUserType] = useState<UserType>(data?.userType || UserType.USER);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Email is required",
        title: "Error",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreateUser!({
          data: {
            name,
            email,
            phoneNumber: phone,
            userType,
            surname,
            password,
          },
        });
      }
      if (actionType === "UPDATE") {
        await handleUpdateUser!({
          where: {
            id: id,
          },
          data: {
            name: {
              set: name,
            },
            surname: {
              set: surname,
            },
            email: {
              set: email,
            },
            phoneNumber: {
              set: phone,
            },

            userType: {
              set: userType,
            },

            password: {
              set: password,
            },
          },
        });
      }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} User
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to={`/users`} as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="name"
                value={name}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setName(e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Surname</FormLabel>
              <Input
                placeholder="Surname"
                value={surname}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setSurname(e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Email</FormLabel>
              <Input
                type={"email"}
                placeholder="Email"
                value={email}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setEmail(e.target.value)
                }
              />
            </GridItem>

            <GridItem>
              <FormLabel>Password</FormLabel>
              <Input
                type={"password"}
                placeholder="Password"
                value={password}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setPassword(e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Company Password</FormLabel>
              <Input
                type={"password"}
                placeholder="Company Password"
                value={companyEmailPassword}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setCompanyEmailPassword(e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Phone</FormLabel>
              <Input
                placeholder="Phone"
                value={phone}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setPhone(e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Role</FormLabel>
              <Select value={userType} onChange={(e: any) => setUserType(e.target.value)}>
                <option value={UserType.USER}>User</option>
                <option value={UserType.ADMIN}>Admin</option>
                <option value={UserType.COMPANY}>Company</option>
                <option value={UserType.MANAGER}>Manager</option>
                <option value={UserType.SALESMAN}>Salesman</option>
              </Select>
            </GridItem>
          </SimpleGrid>
          <Button colorScheme={"green"} type="submit" mt={6}>
            Submit
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
