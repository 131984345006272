import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { ContentsQuery, ContentType, MediaType } from "../../generated/graphql";
import {
  Box,
  Wrap,
  WrapItem,
  Tbody,
  Icon,
  Td,
  Tr,
  IconButton,
  Flex,
  Avatar,
} from "@chakra-ui/react";
import { FcApproval, FcCancel, FcStart } from "react-icons/fc";

const SortableItem = SortableElement(
  ({
    content,
    productOrder,
  }: {
    content: ContentsQuery["contents"][0];
    productOrder: number;
  }) => (
    <Tr background={"white"}>
      <Td>{productOrder + 1}</Td>
      <Td background={"white"} maxWidth={"300px"}>
        {content.contentType === ContentType.TEXT && (
          <Box>{content.descriptions[0]?.title}</Box>
        )}
        {content.contentType === ContentType.LIST && (
          <Box>
            <Wrap>
              {content.photos.map((p, i) => (
                <WrapItem key={i}>
                  <Avatar
                    size={"sm"}
                    src={`${process.env.REACT_APP_CDN}/semix/${p}?width=150&height=150&fit=cover`}
                  />
                </WrapItem>
              ))}
            </Wrap>
            {(content.mediaType === MediaType.SIDEBYSIDE ||
              content.mediaType === MediaType.NOIMAGE) && (
              <Box mt={6}>
                <Box>{content.descriptions[0]?.title}</Box>
              </Box>
            )}
          </Box>
        )}
        {content.contentType === ContentType.IMAGE && (
          <Box>
            <Wrap>
              {content.photos.map((p, i) => (
                <WrapItem key={i}>
                  <Avatar
                    size={"sm"}
                    src={`${process.env.REACT_APP_CDN}/semix/${p}?width=150&height=150&fit=cover`}
                  />
                </WrapItem>
              ))}
            </Wrap>

            {(content.mediaType === MediaType.SIDEBYSIDE ||
              content.mediaType === MediaType.NOIMAGE) && (
              <Box mt={6}>
                <Box>{content.descriptions[0]?.title}</Box>
              </Box>
            )}
          </Box>
        )}
        {content.contentType === ContentType.VIDEO && content.video && (
          <Flex>
            <a href={content.video} target="_blank" rel="noreferrer">
              <Icon as={FcStart} fontSize={"20px"} mr={2} />
            </a>
            {content.video.replace(/.+\/\/|www.|\..+/g, "").toUpperCase()} Video
          </Flex>
        )}
      </Td>
      <Td textAlign={"center"}>{content.contentType}</Td>
      <Td textAlign={"center"}>
        {content.contentType === ContentType.IMAGE ? content.mediaType : "-"}
      </Td>

      <Td textAlign={"center"}>
        {content.showOnWeb ? (
          <IconButton aria-label="Change Status" icon={<FcApproval />} />
        ) : (
          <IconButton aria-label="Change Status" icon={<FcCancel />} />
        )}
      </Td>

      <Td textAlign={"center"}>
        {content.showOnProposal ? (
          <IconButton aria-label="Change Status" icon={<FcApproval />} />
        ) : (
          <IconButton aria-label="Change Status" icon={<FcCancel />} />
        )}
      </Td>
    </Tr>
  )
);

const SortableList = SortableContainer(
  ({ items }: { items: ContentsQuery["contents"] }) => {
    return (
      <Tbody>
        {items.map((value, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              productOrder={index}
              content={value}
            />
          );
        })}
      </Tbody>
    );
  }
);

class OrderList extends React.Component<
  {
    items: ContentsQuery["contents"];
    setOrder: (data: any) => void;
  },
  { setOrder: any; items: ContentsQuery["contents"] }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    this.setState(({ items }) => {
      const newOrder = arrayMoveImmutable(items, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: newOrder,
      };
    });
  };

  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

export default OrderList;
