import { Stack, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons/lib";
import { Link } from "react-router-dom";

interface Props {
  url: string;
  title: string;
  icon: IconType;
}
const SidebarMenuItem: React.FC<Props> = ({ url, title, icon }) => {
  return (
    <Stack
      as={Link}
      display="flex"
      to={url}
      direction="row"
      spacing="4"
      alignItems="center"
      _hover={{ color: "white", bgColor: "#1b1b28", cursor: "pointer" }}
      py={2}
      px={2}
      fontSize="15px"
      fontWeight={"semibold"}
    >
      <Icon as={icon} w={4} h={4} />
      <Text>{title}</Text>
    </Stack>
  );
};

export default SidebarMenuItem;
