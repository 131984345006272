import { Box, Spinner } from "@chakra-ui/react";
const Loading = () => {
  return (
    <Box
      position={"fixed"}
      left={0}
      top={0}
      width={"100vw"}
      height="100vh"
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
    >
      <Spinner />
    </Box>
  );
};

export default Loading;
