import { BrowserRouter, Routes, Route } from "react-router-dom";

import RequireAuth from "./RequireAuth";
import NotRequireAuth from "./NotRequireAuth";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Category from "../pages/category/Category";

import Description from "../pages/description/Description";

import Page from "../pages/page/Page";

import User from "../pages/user/User";
import Content from "../pages/content/Content";

import Lesson from "../pages/lesson/Lesson";
import Question from "../pages/question/Question";
import Choice from "../pages/choice/Choice";

import News from "../pages/news/News";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NotRequireAuth />}>
          <Route index element={<Login />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="/category/*" element={<Category />} />
          <Route path="/description/*" element={<Description />} />
          <Route path="/users/*" element={<User />} />
          <Route path="/page/*" element={<Page />} />
          <Route path="/content/:field/:id/*" element={<Content />} />
          <Route path="/lesson/*" element={<Lesson />} />
          <Route path="/question/*" element={<Question />} />
          <Route path="/choice/*" element={<Choice />} />
          <Route path="/news/*" element={<News />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
