import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  useCreateDescriptionMutation,
  CreateDescriptionMutationVariables,
} from "../../generated/graphql";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

const Create = () => {
  const location = useLocation();
  const { setDescriptionToInput } = location.state as any;
  let descriptionType = false;
  const [searchParams] = useSearchParams();
  const [createDescription] = useCreateDescriptionMutation();
  const navigation = useNavigate();
  const customLink = searchParams.get("customLink");
  const toast = useToast();
  const params = useParams();
  let param: any = params["*"];
  param = param.split("/");
  console.log(param, "PARAMS");
  const [action, language, field, fieldId] = [param[0], param[1], param[2], param[3]];

  if (!setDescriptionToInput) {
    descriptionType =
      window.localStorage.getItem(`${field}-setDescriptionToInput`) === "true"
        ? true
        : false;
  } else {
    window.localStorage.setItem(`${field}-setDescriptionToInput`, setDescriptionToInput);
    descriptionType = setDescriptionToInput;
  }

  const handleCreateDescription = async (data: CreateDescriptionMutationVariables) => {
    try {
      await createDescription({
        variables: {
          data: data.data,
        },
      });
      return toast({
        status: "success",
        title: "Success",
        description: "Description added",
        duration: 1000,
        onCloseComplete: () => {
          if (customLink) {
            return navigation(`${customLink}`);
          }
          return navigation(`/${field}`);
        },
      });
    } catch (error) {
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };
  return (
    <Form
      field={field}
      fieldId={fieldId}
      language={language}
      handleCreateDescription={handleCreateDescription}
      actionType="CREATE"
      descriptionType={descriptionType}
    />
  );
};

export default Create;
