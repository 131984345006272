import React from "react";
import {
  UpdateContentMutationVariables,
  useContentQuery,
  useUpdateContentMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateContent] = useUpdateContentMutation();
  const params = useParams();
  const field = params.field;
  const navigation = useNavigate();
  const toast = useToast();

  const { loading, error, data } = useContentQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to={"/page"} replace />;
  }

  const handleUpdateContent = async (data: UpdateContentMutationVariables) => {
    try {
      await updateContent({
        variables: {
          ...data,
        },
      });

      return toast({
        status: "success",
        title: "Success",
        description: "Content updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation(`/content/${field}/${params.id!}`);
        },
      });
    } catch (error: any) {
      console.log(error.message);
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };

  return (
    <Form
      field={field!}
      actionType="UPDATE"
      data={data?.content}
      id={id}
      handleUpdateContent={handleUpdateContent}
      fieldId={params.id!}
    />
  );
};

export default Update;
