import { useState } from "react";
import { createSlug } from "../../utils/slug";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import {
  CreateChoiceMutationVariables,
  ChoiceQuery,
  UpdateChoiceMutationVariables,
  useQuestionQuery,
  LanguageOptions,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";

interface Props {
  handleCreateChoice?: (data: CreateChoiceMutationVariables) => Promise<any>;
  handleUpdateChoice?: (data: UpdateChoiceMutationVariables) => Promise<any>;
  id?: string;
  questionId: string;
  data?: ChoiceQuery["choice"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({
  handleCreateChoice,
  handleUpdateChoice,
  id,
  data,
  questionId,
  actionType,
}) => {
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [credit, setCredit] = useState(1);
  const [isCorrect, setIsCorrect] = useState(true);

  const { data: questionData, loading: questionLoading } = useQuestionQuery({
    variables: {
      where: {
        id: questionId,
      },
      descriptionsWhere: {
        language: {
          equals: LanguageOptions.TR,
        },
      },
    },
  });
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Title is required",
        title: "Error",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreateChoice!({
          data: {
            descriptions: {
              create: [
                {
                  title,
                  slug: createSlug(title),
                },
              ],
            },
            credit,
            isCorrect,
            question: {
              connect: {
                id: questionId,
              },
            },
          },
        });
      }
      if (actionType === "UPDATE") {
        await handleUpdateChoice!({
          where: {
            id: id,
          },
          data: {
            credit: {
              set: credit,
            },
            isCorrect: {
              set: isCorrect,
            },
          },
        });
      }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Choice for{" "}
          {questionLoading ? <Spinner /> : questionData?.question?.descriptions[0].title}
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/question`}
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            {actionType === "CREATE" && (
              <GridItem
                colSpan={{
                  base: 2,
                  md: 2,
                }}
              >
                <FormLabel>Title</FormLabel>

                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                />
              </GridItem>
            )}

            <GridItem
              colSpan={{
                base: 2,
                md: 2,
              }}
            >
              <FormLabel>Credit</FormLabel>

              <Input
                type="number"
                step={1}
                value={credit}
                onChange={(e) => setCredit(Number(e.target.value))}
                placeholder="Title"
              />
            </GridItem>

            <GridItem
              colSpan={{
                base: 2,
                md: 2,
              }}
            >
              <FormLabel>Is Correct</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={isCorrect ? "green" : "gray"}
                    onClick={() => setIsCorrect(true)}
                  >
                    ACTIVE
                  </Button>
                </GridItem>

                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!isCorrect ? "red" : "gray"}
                    onClick={() => setIsCorrect(false)}
                  >
                    PASSIVE
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
          <Button colorScheme={"green"} type="submit" mt={6}>
            Submit
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
