import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateQuestionMutationVariables,
  useCreateQuestionMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const [createPage] = useCreateQuestionMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const handleCreateQuestion = async (data: CreateQuestionMutationVariables) => {
    try {
      await createPage({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "Success",
        description: "Question added",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/question");
        },
      });
    } catch (error) {
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };
  return <Form handleCreateQuestion={handleCreateQuestion} actionType="CREATE" />;
};

export default Create;
