import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateChoiceMutationVariables,
  useCreateChoiceMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

interface Props {
  questionId: string;
}
const Create: React.VFC<Props> = ({ questionId }) => {
  const [createChoice] = useCreateChoiceMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const handleCreateChoice = async (data: CreateChoiceMutationVariables) => {
    try {
      await createChoice({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "Success",
        description: "Choice added",
        duration: 1000,
        onCloseComplete: () => {
          return navigation(`/question/detail/${questionId}`);
        },
      });
    } catch (error) {
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };
  return (
    <Form
      questionId={questionId}
      handleCreateChoice={handleCreateChoice}
      actionType="CREATE"
    />
  );
};

export default Create;
