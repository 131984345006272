import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  useCreateUserMutation,
  CreateUserMutationVariables,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const [createUser] = useCreateUserMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const handleCreateUser = async (data: CreateUserMutationVariables) => {
    try {
      await createUser({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "Success",
        description: "User added",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/users");
        },
      });
    } catch (error) {
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };
  return <Form handleCreateUser={handleCreateUser} actionType="CREATE" />;
};

export default Create;
