import {
  Box,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { FcFullTrash } from "react-icons/fc";

interface Props {
  title: string;
  id: string;
  size?: string;
  refetch: () => Promise<any>;
  handleDelete: (id: string) => Promise<any>;
}
const DeleteItem: React.VFC<Props> = ({
  title,
  id,
  size = "md",
  refetch,
  handleDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const deleteItem = async (id: string) => {
    setLoading(true);
    try {
      await handleDelete(id);
      return toast({
        duration: 1500,
        title: "Item deleted",
        status: "success",
        description: "Item successfully deleted",
        onCloseComplete: async () => {
          setLoading(false);
          onClose();
          return refetch();
        },
      });
    } catch (error) {
      setLoading(false);
      return toast({
        duration: 1500,
        title: "Error",
        status: "error",
        description: "An error occured",
      });
    }
  };
  return (
    <Box>
      <IconButton
        size={size}
        icon={<FcFullTrash />}
        aria-label="Delete Item"
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>You are about to delete {title}. Are you sure?</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              isDisabled={loading}
              colorScheme={"red"}
              onClick={() => deleteItem(id)}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeleteItem;
