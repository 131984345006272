import React, { useState } from "react";
import {
  Box,
  Button,
  SimpleGrid,
  GridItem,
  FormLabel,
  Input,
  Flex,
  IconButton,
  Link,
  useToast,
} from "@chakra-ui/react";
import {
  LanguageOptions,
  CreateDescriptionMutationVariables,
  UpdateDescriptionMutationVariables,
  DescriptionQuery,
} from "../../generated/graphql";
import { Editor } from "@tinymce/tinymce-react";
import PageLayout from "../../components/ui/PageLayout";
import { FcList } from "react-icons/fc";
import { createSlug } from "../../utils/slug";

interface Props {
  handleCreateDescription?: (data: CreateDescriptionMutationVariables) => Promise<any>;
  handleUpdateDescription?: (data: UpdateDescriptionMutationVariables) => Promise<any>;
  data?: DescriptionQuery["description"];
  id?: string;
  actionType: "CREATE" | "UPDATE";
  fieldId: string;
  field: string;
  language: LanguageOptions;
  descriptionType?: boolean;
  headlineTitle?: string;
}

const Form: React.FC<Props> = ({
  handleCreateDescription,
  handleUpdateDescription,
  actionType,
  language,
  field,
  fieldId,
  id,
  data,
  descriptionType = false,
  headlineTitle = "Short Description",
}) => {
  const [description, setDescription] = useState(data?.description || "");
  const [title, setTitle] = useState(data?.title || "");
  const [headline, setHeadline] = useState(data?.headline || "");
  const toast = useToast();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Title is required",
        title: "Error",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        const slug = createSlug(title);
        await handleCreateDescription!({
          data: {
            title,
            description,
            headline,
            slug,
            language,
            category: field === "category" ? { connect: { id: fieldId } } : {},
            page: field === "page" ? { connect: { id: fieldId } } : {},
            lesson: field === "lesson" ? { connect: { id: fieldId } } : {},
            content: field === "content" ? { connect: { id: fieldId } } : {},
          },
        });
      } else {
        const slug = createSlug(title);
        await handleUpdateDescription!({
          data: {
            title: {
              set: title,
            },
            description: {
              set: description,
            },
            headline: {
              set: headline,
            },
            slug: {
              set: slug,
            },
            language: {
              set: language,
            },
          },
          where: { id },
        });
      }
    } catch (error) {
      console.log("🌵💜🐢 error", error);
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Description
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/${field}`}
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem colSpan={2}>
              <FormLabel>Title</FormLabel>
              <Input
                value={title}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setTitle(e.target.value)
                }
                placeholder="Title"
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>{headlineTitle}</FormLabel>
              <Input
                value={headline}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setHeadline(e.target.value)
                }
                placeholder={headlineTitle}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Description</FormLabel>
              {descriptionType ? (
                <Input
                  value={description}
                  onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                    setDescription(e.target.value)
                  }
                  placeholder="Description"
                />
              ) : (
                <Editor
                  apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                  initialValue={description}
                  outputFormat="html"
                  onEditorChange={(e) => setDescription(e)}
                  init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: "Category description...",
                    height: 300,
                    menubar: false,
                    min_height: 200,
                    max_height: 500,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                      "removeformat | code",
                  }}
                />
              )}
            </GridItem>
          </SimpleGrid>
          <Button colorScheme={"green"} type="submit" mt={6}>
            Submit
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
