import { useState } from "react";
import { createSlug } from "../../utils/slug";
import { Editor } from "@tinymce/tinymce-react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import {
  CreateNewsMutationVariables,
  NewsQuery,
  UpdateNewsMutationVariables,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import UploadDropzone from "../../components/dropzone/UploadDropzone";

interface Props {
  handleCreateNews?: (data: CreateNewsMutationVariables) => Promise<any>;
  handleUpdateNews?: (data: UpdateNewsMutationVariables) => Promise<any>;
  id?: string;
  data?: NewsQuery["findUniqueNews"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({
  handleCreateNews,
  handleUpdateNews,
  id,
  data,
  actionType,
}) => {
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [headline, setHeadline] = useState("");
  const [image, setImage] = useState(data?.image ? data.image : "");
  const [status, setStatus] = useState(data?.status);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Title is required",
        title: "Error",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreateNews!({
          data: {
            status,
            image,
            descriptions: {
              create: [
                {
                  title,
                  description,
                  slug: createSlug(title),
                  headline,
                },
              ],
            },
          },
        });
      }
      if (actionType === "UPDATE") {
        await handleUpdateNews!({
          where: {
            id: id,
          },
          data: {
            status: {
              set: status,
            },
            image: {
              set: image,
            },
          },
        });
      }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {actionType === "CREATE" ? "Create" : "Update"} Page
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to={`/news`} as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            <GridItem colSpan={2}>
              <UploadDropzone images={image} setImages={setImage} isSingle />
            </GridItem>
            {actionType === "CREATE" && (
              <GridItem
                colSpan={{
                  base: 2,
                  md: 2,
                }}
              >
                <FormLabel>Title</FormLabel>

                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                />
              </GridItem>
            )}

            {actionType === "CREATE" && (
              <GridItem
                colSpan={{
                  base: 2,
                  md: 2,
                }}
              >
                <FormLabel>Headline</FormLabel>

                <Input
                  value={headline}
                  onChange={(e) => setHeadline(e.target.value)}
                  placeholder="Headline"
                />
              </GridItem>
            )}

            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Description</FormLabel>
                <Editor
                  apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                  outputFormat="html"
                  onEditorChange={(e) => setDescription(e)}
                  init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: "Description about page",
                    height: 300,
                    menubar: false,
                    min_height: 200,
                    max_height: 500,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                      "removeformat | code",
                  }}
                />
              </GridItem>
            )}

            <GridItem
              colSpan={{
                base: 2,
                md: 2,
              }}
            >
              <FormLabel>Status</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={status ? "green" : "gray"}
                    onClick={() => setStatus(true)}
                  >
                    ACTIVE
                  </Button>
                </GridItem>

                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!status ? "red" : "gray"}
                    onClick={() => setStatus(false)}
                  >
                    PASSIVE
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
          <Button colorScheme={"green"} type="submit" mt={6}>
            Submit
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
