import {
  Flex,
  GridItem,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsCardImage, BsTrash } from "react-icons/bs";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useUploadFileMutation } from "../../generated/graphql";

interface SortablePhotoProps {
  deletePhoto: any;
  photo: any;
}
interface SortablePhotosProps {
  items: any[];
  deletePhoto: any;
}

const SortablePhoto = SortableElement(({ photo, deletePhoto }: SortablePhotoProps) => {
  return (
    <GridItem position="relative">
      <IconButton
        position="absolute"
        bottom="0"
        right="0"
        size="sm"
        fontSize="22px"
        colorScheme="red"
        aria-label="delete image"
        icon={<BsTrash />}
        onClick={() => deletePhoto(photo)}
      />
      <Image src={`${process.env.REACT_APP_CDN}/${photo}`} w="full" />
    </GridItem>
  );
});

const SortablePhotos = SortableContainer(
  ({ deletePhoto, items }: SortablePhotosProps) => {
    return (
      <SimpleGrid columns={8} gap={3} mb="4">
        {items
          .filter((item) => item.length !== 0)
          .map((photo, index) => {
            return (
              <SortablePhoto
                deletePhoto={deletePhoto}
                photo={photo}
                key={index}
                index={index}
              />
            );
          })}
      </SimpleGrid>
    );
  }
);

interface IUploadDropzone {
  audio: string;
  setAudio: any;
}

const UploadDropzone: React.VFC<IUploadDropzone> = ({ audio, setAudio }) => {
  const [uploadPhoto, { loading: uploadLoading }] = useUploadFileMutation();

  const [addedAudio, setAddedAudio] = useState<string>(audio || "");
  // const [addedPhotos, setAddedPhotos] = useState<string[]>(images ? images : []);
  const [change, setChange] = useState(1);

  const deleteMedia = (fileName: any) => {
    setAddedAudio("");
  };

  useEffect(() => {
    setAudio(addedAudio);
  }, [addedAudio, setAddedAudio, setAudio]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      await acceptedFiles.map(async (a: any) => {
        try {
          const result = await uploadPhoto({
            variables: {
              file: a,
            },
          });

          setAddedAudio(result.data?.uploadFile.file);

          setChange(change + Math.random());
        } catch (error) {
          console.log(error);
        }
      });
    },
    [change, uploadPhoto]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <>
      <Flex
        w="100%"
        h="150px"
        color="black"
        border="1px solid #ccc"
        justifyContent="center"
        alignItems="center"
        rounded="sm"
        _hover={{ cursor: "pointer" }}
        {...getRootProps()}
      >
        <Flex flexDirection="column" alignItems="center">
          {uploadLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner size="lg" />
            </Flex>
          ) : isDragActive ? (
            <Text fontWeight="bold">Drop here</Text>
          ) : (
            <>
              <div>
                <BsCardImage size="36" />
              </div>
              <Text fontWeight="bold" fontSize="medium">
                Click here to add a audionjnf or drag &amp; drop
              </Text>
            </>
          )}
        </Flex>
        {/* <form encType="multipart/form-data"> */}
        <input {...getInputProps({ multiple: false })} />
        {/* </form> */}
      </Flex>
    </>
  );
};

export default UploadDropzone;
