import { Box, Flex, IconButton, Table, Th, Thead, Tr } from "@chakra-ui/react";
import { FcList } from "react-icons/fc";
import { Link } from "react-router-dom";
import Error from "../../components/error/Error";
import Loading from "../../components/loading/Loading";
import PageLayout from "../../components/ui/PageLayout";
import {
  SortOrder,
  ContentsQuery,
  useContentsQuery,
  useUpdateContentMutation,
} from "../../generated/graphql";
import OrderList from "./OrderList";

interface Props {
  id?: string;
  field: string;
}

function Order({ id, field }: Props) {
  const { loading, error, data } = useContentsQuery({
    variables: {
      orderBy: {
        order: SortOrder.asc,
      },
      where: {
        [`${field}Id`]: {
          equals: id,
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const [order] = useUpdateContentMutation();

  const setOrder = (data: ContentsQuery["contents"]) => {
    data!.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            data: {
              order: { set: i + 1 },
            },
            where: {
              id: element.id,
            },
          },
        });
      } catch (error: any) {
        console.log("err 👉", error);
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Box>
        {id} ve {field}
        <Error />
      </Box>
    );
  }

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Order Contents
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/content/${field}/${id}`}
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>

      <Table width={"100%"} mx="auto">
        <Thead>
          <Tr>
            <Th textAlign="center">#</Th>
            <Th>Title</Th>
            <Th textAlign="center">Content Type</Th>
            <Th textAlign="center">Media Type</Th>
            <Th textAlign="center">Web</Th>
            <Th textAlign="center">Proposal</Th>
          </Tr>
        </Thead>
        <OrderList items={data?.contents!} setOrder={setOrder} />
      </Table>
    </PageLayout>
  );
}

export default Order;
