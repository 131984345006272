import { Navigate, Outlet, useLocation } from "react-router-dom";

function NotRequireAuth() {
  const location = useLocation();
  const token = localStorage.getItem("token");

  if (token) return <Navigate to="/dashboard" state={{ from: location }} replace />;

  return <Outlet />;
}

export default NotRequireAuth;
