import { Navigate, Outlet, useLocation } from "react-router-dom";
import Layout from "../components/ui/Layout";

function RequireAuth() {
  const location = useLocation();
  const token = localStorage.getItem("token");

  if (!token) return <Navigate to="/" state={{ from: location }} replace />;

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default RequireAuth;
