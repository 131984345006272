import { Flex, Stack, Button, Heading } from "@chakra-ui/react";
import { useStaffStore } from "../../../store/staff";
import SidebarMenuItem from "./SidebarMenuItem";

import { BsXDiamondFill } from "react-icons/bs";

function Sidebar() {
  const { logoutStaff } = useStaffStore();

  return (
    <Flex
      flexDirection="column"
      bgColor="gray.800"
      width={{
        base: "full",
        md: "15%",
      }}
      py={4}
      px={2}
    >
      <Stack direction="column" spacing={2} color="white" alignContent="start">
        <Heading px={2} fontSize="md" mb={6}>
          İlgi Otizm
        </Heading>

        <SidebarMenuItem icon={BsXDiamondFill} title="Categories" url="/category" />
        <SidebarMenuItem icon={BsXDiamondFill} title="Pages" url="/page" />
        <SidebarMenuItem icon={BsXDiamondFill} title="Lessons" url="/lesson" />
        <SidebarMenuItem icon={BsXDiamondFill} title="Questions" url="/question" />
        <SidebarMenuItem icon={BsXDiamondFill} title="Users" url="/users" />
        <SidebarMenuItem icon={BsXDiamondFill} title="News" url="/news" />
      </Stack>

      <Button
        mt="auto"
        colorScheme="red"
        // alignSelf="flex-end"
        onClick={() => {
          logoutStaff();
          window.location.href = "/";
        }}
      >
        Logout
      </Button>
    </Flex>
  );
}

export default Sidebar;
