import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  UpdateDescriptionMutationVariables,
  useUpdateDescriptionMutation,
  useDescriptionQuery,
} from "../../generated/graphql";
import { useNavigate, useParams, Navigate, useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";

const Create = () => {
  const location = useLocation();
  const { setDescriptionToInput } = location.state as any;
  let descriptionType = false;

  const [updateDescription] = useUpdateDescriptionMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const params = useParams();
  let param: any = params["*"];
  param = param.split("/");
  const [action, language, field, fieldId, id] = [
    param[0],
    param[1],
    param[2],
    param[3],
    param[4],
  ];

  if (!setDescriptionToInput) {
    descriptionType =
      window.localStorage.getItem(`${field}-setDescriptionToInput`) === "true"
        ? true
        : false;
  } else {
    window.localStorage.setItem(`${field}-setDescriptionToInput`, setDescriptionToInput);
    descriptionType = setDescriptionToInput;
  }

  console.log("🌵💜🐢", action, language, field, fieldId, id);

  const { loading, error, data } = useDescriptionQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to={"/product"} replace />;
  }

  const handleUpdateDescription = async (data: UpdateDescriptionMutationVariables) => {
    try {
      await updateDescription({
        variables: {
          ...data,
        },
      });
      return toast({
        status: "success",
        title: "Success",
        description: "Description updated",
        duration: 1000,
        onCloseComplete: () => {
          return navigation(`/${field}`);
        },
      });
    } catch (error) {
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };
  return (
    <Form
      field={field}
      fieldId={fieldId}
      language={language}
      id={id}
      data={data?.description}
      handleUpdateDescription={handleUpdateDescription}
      actionType="UPDATE"
      descriptionType={descriptionType}
    />
  );
};

export default Create;
