import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreatePageMutationVariables,
  useCreatePageMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const [createPage] = useCreatePageMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const handleCreatePage = async (data: CreatePageMutationVariables) => {
    try {
      await createPage({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "Success",
        description: "Page added",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/page");
        },
      });
    } catch (error) {
      return toast({
        status: "error",
        title: "Error",
        description: "An error occured",
        duration: 1000,
      });
    }
  };
  return <Form handleCreatePage={handleCreatePage} actionType="CREATE" />;
};

export default Create;
