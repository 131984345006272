import { Fragment } from "react";
import {
  Box,
  Flex,
  IconButton,
  SimpleGrid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Rating } from "react-simple-star-rating";
import { $enum } from "ts-enum-util";
import {
  LanguageOptions,
  useQuestionQuery,
  useDeleteChoiceMutation,
} from "../../generated/graphql";
import { FcRefresh, FcEditImage, FcPlus } from "react-icons/fc";
import dayjs from "dayjs";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Description from "../../components/description/Description";
import DeleteItem from "../../components/delete/DeleteItem";

interface Props {
  id: string;
}

const Detail: React.VFC<Props> = ({ id }) => {
  const [deleteChoice] = useDeleteChoiceMutation();
  const { loading, error, data, refetch } = useQuestionQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteChoice({
        variables: {
          where: {
            id,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Question Detail
          </Box>
          <Flex experimental_spaceX={4}>
            <IconButton
              icon={<FcRefresh />}
              aria-label="Refetch Query"
              onClick={() => refetch()}
            />
            <IconButton
              to={`/question/update/${id}`}
              as={Link}
              icon={<FcEditImage />}
              aria-label="Add Item"
              onClick={() => refetch()}
            />
          </Flex>
        </Flex>
        <SimpleGrid columns={2} gap={6}>
          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Created At" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${dayjs(data?.question?.createdAt).format("DD MMM YYYY HH:mm")}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Updated At" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${dayjs(data?.question?.updatedAt).format("DD MMM YYYY HH:mm")}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Category" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.question?.category.descriptions[0].title}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Is Paid" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.question?.isPaid ? "Yes" : "No"}`}
              />
            </InputGroup>
          </GridItem>
        </SimpleGrid>
      </PageLayout>
      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Question
          </Box>
          <Flex experimental_spaceX={4}>
            <IconButton
              icon={<FcRefresh />}
              aria-label="Refetch Query"
              onClick={() => refetch()}
            />
          </Flex>
        </Flex>
        <SimpleGrid columns={3} gap={4}>
          {$enum(LanguageOptions).map((language) => {
            const description = data?.question?.descriptions.filter(
              (d) => d.language === language
            );
            if (!description) {
              return "-";
            }
            return description?.length === 0 ? (
              <Box fontSize={"sm"}>{language} not found</Box>
            ) : (
              <Box>
                <Box fontSize={"sm"}>
                  <Box fontWeight={"bold"}>
                    {language} | {description[0].title}
                  </Box>
                  {description[0].description && parse(description[0].description)}
                </Box>
              </Box>
            );
          })}
        </SimpleGrid>
      </PageLayout>

      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Choices
          </Box>
          <Flex experimental_spaceX={4}>
            <IconButton
              icon={<FcPlus />}
              aria-label="Refetch Query"
              to={`/choice/create/${id}`}
              as={Link}
            />
          </Flex>
        </Flex>
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Descriptions</Th>
              <Th textAlign={"center"}>Credit(s)</Th>

              <Th textAlign={"center"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.question?.choices.length === 0 ? (
              <Tr>
                <Td colSpan={3}>No choice available</Td>
              </Tr>
            ) : (
              data?.question?.choices.map((c, i) => {
                return (
                  <Tr key={c.id}>
                    <Td>{i + 1}</Td>
                    <Td>
                      <Description
                        descriptions={c.descriptions}
                        field="choice"
                        refetch={refetch}
                        id={c.id}
                        customLink={`question/detail/${id}`}
                      />
                    </Td>
                    <Td textAlign={"center"}>{c.credit}</Td>
                    <Td textAlign={"center"}>
                      <DeleteItem
                        handleDelete={handleDelete}
                        refetch={refetch}
                        title={c.descriptions[0].title}
                        id={c.id}
                      />
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </PageLayout>
    </Fragment>
  );
};

export default Detail;
