import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useUsersQuery, useDeleteUserMutation } from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";

const All = () => {
  const [deleteUser] = useDeleteUserMutation();

  const { loading, error, data, refetch } = useUsersQuery({
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteUser({
        variables: {
          where: {
            id,
          },
        },
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          User List
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
          <IconButton
            to={`/users/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Phone Number</Th>
            <Th>Role</Th>
            <Th textAlign={"center"}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.users.map((user, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>
                  {user.name} {user.surname}
                </Td>
                <Td>{user.email}</Td>
                <Td>{user.phoneNumber}</Td>
                <Td>{user.userType}</Td>
                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/users/update/${user.id}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={user.id}
                        refetch={refetch}
                        title={`${user.name} ${user.surname}`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
